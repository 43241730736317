import moment from "moment";

import { getSchedule, getSchedule_getSchedule } from "../graphql/__generated__/getSchedule";

import { convertScheduleDataTypes } from "./types";
import "moment-timezone";
import "moment/locale/ru";

export const sortScheduleData = (scheduleData: convertScheduleDataTypes[]) => {
  const timezone = moment.tz.guess(); // get the current timezone

  return scheduleData
    .sort((a, b) => {
      const dateA = moment(a.date, "D MMM, ddd");
      const dateB = moment(b.date, "D MMM, ddd");

      if (dateA.month() !== dateB.month()) {
        return dateA.month() - dateB.month();
      }

      return dateA.date() - dateB.date();
    })
    .map((item) => {
      item.times = item.times.sort((a, b) => {
        const momentA = moment.tz(a, timezone);
        const momentB = moment.tz(b, timezone);
        return momentA.diff(momentB);
      });

      return item;
    });
};

export const changeFormatDataSchedule = (allScheduleData: getSchedule | undefined) => {
  const convertScheduleData: convertScheduleDataTypes[] = [];
  const timeTz = moment.tz;

  const timeZone = timeTz.guess(); // get the current timeZone
  timeTz.setDefault(timeZone);

  allScheduleData?.getSchedule.forEach((item: getSchedule_getSchedule | undefined | null) => {
    const date = timeTz(item?.start, timeZone).format("D MMM ddd").replace(".", ",");
    const start = timeTz(item?.start, timeZone);
    const end = timeTz(item?.end, timeZone);

    // Add 1 minute to end time if it ends on 59th minute
    if (end.format("mm") === "59") {
      end.add(1, "minute");
    }

    const startTime = start.format("HH:mm");
    const endTime = end.format("HH:mm");
    const times = `${startTime}–${endTime}`;

    const existingDate = convertScheduleData.find((i) =>
      i.date === date);
    if (existingDate) {
      existingDate.times.push(times);
    } else {
      convertScheduleData.push({ id: convertScheduleData.length + 1, date, times: [times] });
    }
  });

  return convertScheduleData;
};
