import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import { Button, ButtonColorEnum, ButtonSizeEnum } from "@/components/common/button";
import Rating from "@/components/common/rating";
import { isBrowser } from "@/utils/env";
import { declenateWord } from "@/utils/stringUtils";

import Review from "../../common/review";
import { EXPERT_REVIEWS_PAGE_SIZE } from "../constants";
import { getExpertPageData_getReviews } from "../graphql/__generated__/getExpertPageData";
import {
  getMoreExpertReviewsNew,
  getMoreExpertReviewsNewVariables,
} from "../graphql/__generated__/getMoreExpertReviewsNew";
import { GET_MORE_EXPERT_REVIEWS } from "../graphql/GET_MORE_EXPERT_REVIEWS";

import "./styles.scss";

import { ReviewsProps } from "./types";

const Reviews = ({ expertId, rating, reviewsInitial }: ReviewsProps) => {
  const [reviews, setReviews] = useState<getExpertPageData_getReviews>();
  const [currentPage, setCurrentPage] = useState(1);
  const [getMoreEvents,
    {
      data: loadMoreData,
      loading: loadMoreLoading,
      error: loadMoreError,
    }] = useLazyQuery<getMoreExpertReviewsNew, getMoreExpertReviewsNewVariables>(
      GET_MORE_EXPERT_REVIEWS,
    );

  useEffect(() => {
    // todo: make page parsing for static generation and delete this check
    if (!isBrowser()) {
      return;
    }
    const searchString = window.location.search;
    const urlParams = new URLSearchParams(searchString.replace("?", ""));
    setCurrentPage(parseInt(urlParams.get("page") ?? "1", 10));
  }, []);

  useEffect(() => {
    if (reviewsInitial) {
      setReviews(reviewsInitial);
    }
  }, [reviewsInitial]);

  const onLoadMoreClick = () => {
    if (reviews?.pageInfo.endCursor !== "") {
      getMoreEvents({
        variables: {
          id: expertId,
          first: EXPERT_REVIEWS_PAGE_SIZE,
          after: reviews?.pageInfo.endCursor,
        },
      });
    }
  };

  useEffect(() => {
    if (loadMoreData?.getReviews && !loadMoreLoading && !loadMoreError) {
      setReviews({
        ...(loadMoreData.getReviews as getExpertPageData_getReviews),
        edges: reviews?.edges.concat(loadMoreData.getReviews.edges as any) ?? [],
        pageInfo: loadMoreData.getReviews.pageInfo,
      });
      setCurrentPage((oldVal) =>
        oldVal + 1);
    }
  }, [loadMoreData, loadMoreLoading, loadMoreError]);

  if (!reviews || reviews.totalCount === 0) {
    return null;
  }

  const remainingCount = (reviews.totalCount ?? 0) > currentPage * EXPERT_REVIEWS_PAGE_SIZE
    ? (reviews.totalCount ?? 0) - currentPage * EXPERT_REVIEWS_PAGE_SIZE
    : 0;

  return (
    <div className="expert-comments">
      <div className="expert-comments__header">
        <h2>
          {reviews.totalCount}
          {" "}
          {declenateWord(reviews.totalCount, ["отзыв", "отзыва", "отзывов"])}
        </h2>
        {" "}
        <Rating value={rating} />
      </div>
      {reviews.edges.map((review) =>
        (
          <Review review={review?.node} key={review?.node.id} />
        ))}
      {reviews.pageInfo.hasNextPage && (
        <Button
          text={`Показать ещё ${Math.min(remainingCount, EXPERT_REVIEWS_PAGE_SIZE)}`}
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.Light}
          onClick={() =>
            onLoadMoreClick()}
          isLoading={loadMoreLoading}
          className="expert-comments__button"
        />
      )}
    </div>
  );
};

export default Reviews;
