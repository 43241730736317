import { FaqDataType } from "./types";

const isFFAllowFirstVideoSessionWithoutPaymentOn = process.env.GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT === "true";

const firstAnswer = isFFAllowFirstVideoSessionWithoutPaymentOn
  ? `Да, мы\u00a0дарим 7\u00a0минут, которые действуют только на\u00a0консультацию по\u00a0видео. При созвоне с\u00a0экспертом подарочные минуты спишутся первыми.`
  : `Да, мы дарим 7 минут при первом пополнении баланса от 99 ₽. При созвоне с экспертом подарочные минуты спишутся первыми.`;

export const ACCORDION_DATA: FaqDataType = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Есть ли у вас пробная консультация?",
      answer: {
        "@type": "Answer",
        text: firstAnswer,
      },
    },
    {
      "@type": "Question",
      name: "Сколько длится консультация?",
      answer: {
        "@type": "Answer",
        text: "Минимальная продолжительность консультации — 7 минут. Время не фиксировано — вы сами решаете, когда завершить звонок.",
      },
    },
    {
      "@type": "Question",
      name: "Как оплатить консультацию?",
      answer: {
        "@type": "Answer",
        text: "Оплата происходит поминутно прямо на сервисе, деньги списываются с баланса вашего профиля. Переводить деньги на банковскую карту эксперта не требуется.",
      },
    },
    {
      "@type": "Question",
      name: "Как пополнить баланс?",
      answer: {
        "@type": "Answer",
        text: "Войдите в Личный кабинет и нажмите кнопку «Пополнить баланс». Минимальная сумма — 99 ₽. Можно использовать банковские карты VISA, Mastercard и МИР.",
      },
    },
    {
      "@type": "Question",
      name: "Я могу получить помощь анонимно?",
      answer: {
        "@type": "Answer",
        text: "Да, можно выключить камеру при звонке по видео, а также заказать у эксперта телефонный звонок. Кроме того, в Личном кабинете вы можете не указывать своё настоящее имя.",
      },
    },
    {
      "@type": "Question",
      name: "Как правильно: Лунара, Лунаро или Lunaro?",
      hideForExpertPage: true,
      answer: {
        "@type": "Answer",
        text: "Наше название связано с Луной, которая в эзотерике покровительствует ментальному, чувственному и эмоциональному в жизни человека. Клиенты нас называют по-всякому: и Лунара, и Лунаро, но правильнее будет Lunaro. Впрочем, зовите нас, как угодно; Лунара или Lunaro — качество наших консультаций от этого не изменится.",
      },
    },
  ],
};
