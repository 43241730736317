import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import { getSchedule, getScheduleVariables } from "../graphql/__generated__/getSchedule";
import { GET_SCHEDULE } from "../graphql/GET_SCHEDULE";

import { swiperBreakpoints } from "./constants";
import { convertScheduleDataTypes, expertScheduleProps } from "./types";
import { changeFormatDataSchedule, sortScheduleData } from "./utils";

import "moment-timezone";
import "moment/locale/ru";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/navigation";
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
import "./styles.scss";

const ExpertSchedule = ({ expertUuid, scrollElement, setIsSchedulEmpty }: expertScheduleProps) => {
  const [isBeginningSlider, setIsBeginningSlider] = useState(true);
  const [isEndSliders, setIsEndSliders] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [scheduleData, setScheduleData] = useState<convertScheduleDataTypes[]>([]);
  const [getSchedule, { data: allScheduleData }] = useLazyQuery<getSchedule, getScheduleVariables>(
    GET_SCHEDULE,
  );

  useEffect(() => {
    const currentDate = moment();
    const yesterday = moment().subtract(1, "days");
    const currentDayWeek = yesterday
      .set({
        hour: 23,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format();
    const sevenDaysFromCurrent = currentDate.add(7, "days");

    if (expertUuid) {
      getSchedule({
        variables: {
          filter: {
            ExpertFilter: [expertUuid],
            DateFilter: {
              laterThan: currentDayWeek,
              earlierThan: sevenDaysFromCurrent,
            },
          },
        },
      });
    }
  }, [expertUuid]);

  useEffect(() => {
    const convertScheduleData = changeFormatDataSchedule(allScheduleData);
    const sortingScheduleData = sortScheduleData(convertScheduleData);

    if (sortingScheduleData.length) {
      setIsSchedulEmpty(false);
    }

    setScheduleData(sortingScheduleData);
  }, [allScheduleData]);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update(); // update swiper instance to reflect new content
    }
  }, [scheduleData]);

  const shouldHideNextButton = scheduleData.length * 100 < swiperInstance?.width! ?? 0;

  if (!scheduleData.length) {
    return null;
  }

  return (
    <div className="expert-schedule" ref={scrollElement}>
      <div className="expert-schedule__block-title">
        <h2 className="expert-schedule__title">Расписание эксперта</h2>
      </div>
      <div
        id="swiper-back"
        className={`expert-schedule__prev-button ${!isBeginningSlider ? "visibleButton" : ""} `}
      >
        <Icon type={IconTypeEnum.Left} size={IconSizeEnum.Size20} />
      </div>
      <Swiper
        breakpoints={swiperBreakpoints}
        className="expert-schedule__swiper"
        slidesPerView="auto"
        onSwiper={setSwiperInstance}
        modules={[Navigation]}
        onSlideChange={(swiper) => {
          setIsEndSliders(swiper.isEnd);
          setIsBeginningSlider(swiper.isBeginning);
        }}
        navigation={{ nextEl: "#swiper-forward", prevEl: "#swiper-back" }}
      >
        {scheduleData?.map((item: convertScheduleDataTypes) =>
          (
            <SwiperSlide key={item.id} className="expert-schedule__carousel-columns">
              <p className="expert-schedule__head-titels">{item.date}</p>
              {item.times.map((time, idx) =>
                (
                  <p key={idx} className="expert-schedule__times">
                    {time}
                  </p>
                ))}
            </SwiperSlide>
          ))}
        {!isBeginningSlider && <div className="expert-schedule__left-shadow" />}
        <div className="expert-schedule__right-shadow" />
      </Swiper>
      <div
        id="swiper-forward"
        className={`expert-schedule__next-button ${
          isEndSliders || shouldHideNextButton ? "hiddenButton" : ""
        }`}
      >
        <Icon type={IconTypeEnum.Right} size={IconSizeEnum.Size20} />
      </div>
    </div>
  );
};

export default ExpertSchedule;
