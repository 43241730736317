import React from "react";

import { Link } from "gatsby";

import { mockDataScope } from "./constants"; // todo: data must be obtained from an expert when it's ready on backend
import "./styles.scss";

const ExpertScopes = () =>
  (
    <div className="scopes">
      <p className="scopes__title">Работаю со сферами жизни</p>
      <div className="scopes__block-scopes">
        {mockDataScope.map((item) =>
          (
            <Link key={item.area} to={item.link} className="scopes__scopes-item">
              {item.area}
            </Link>
          ))}
      </div>
    </div>
  );

export default ExpertScopes;
