import React from "react";

import "./styles.scss";
import { CircularProgressbar } from "react-circular-progressbar";

import { Icon } from "@/components/common/icon";

import { mobileAudioGreetingProps } from "./types";
import "react-circular-progressbar/dist/styles.css";

const MobileAudioGreeting = ({
  progresAudioWidth,
  togglePlayHandler,
  iconAudio,
  isEnded,
}: mobileAudioGreetingProps) =>
  (
    <div onClick={togglePlayHandler} className="mobile-greeting">
      <div className="mobile-greeting__progress-wrapper">
        <CircularProgressbar
          value={isEnded ? 0 : progresAudioWidth}
          className="mobile-greeting__progress-bar"
          styles={{
            path: {
              stroke: "#514A7D",
            },
            trail: {
              stroke: "#D5CDFF",
            },
          }}
        />
        <Icon
          type={iconAudio}
          className={`mobile-greeting__icons ${
            iconAudio === "Play" && "mobile-greeting__icon-play-margin"
          }`}
        />
      </div>

      <p className="mobile-greeting__text">Слушать приветствие</p>
    </div>
  );

export default MobileAudioGreeting;
