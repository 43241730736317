export const mockDataConsultation = [
  {
    title: "Простой ответ «да/нет»",
    description:
      "Общая диагностика вопроса на 3-4 картах — для понимания, почему возникла ситуация и как из неё выйти",
    time: "минут",
    timeRange: "10-15",
  },
  {
    title: "Развёрнутый ответ",
    description:
      "Причины возникновения ситуации и несколько вариантов решения вопроса. Какой жизненный урок вы сейчас проходите и что нужно понять для себя",
    time: "минут",
    timeRange: "30-40",
    isFire: true,
  },
  {
    title: "Детальный ответ",
    description:
      "Всесторонний анализ вопроса, поиск ответов в прошлом, развитие событий в будущем. Совет, как поступить в ситуации. Какой жизненный урок вы сейчас проходите и что нужно понять для себя",
    time: "минут",
    timeRange: "60-90",
  },
];
