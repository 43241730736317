import React from "react";

import { Link } from "gatsby";

import { CatalogSeoUrl } from "@/components/catalog/types";
import { CATALOG_PAGE_NAME } from "@/components/constants";

import { NavigationPanelProps } from "./types";
import "./styles.scss";

const { catalogUrlMap } = require("../../../catalog/seo/seoData.ts");

const NavigationPanel = ({ skill } : NavigationPanelProps) => {
  // Searching for SEO URL to insert as last breadcrumb
  const lastBreadcrumb: CatalogSeoUrl | null = !!skill
    && catalogUrlMap.find((urlItem: CatalogSeoUrl) =>
      urlItem.skills?.length === 1 && urlItem.skills[0] === skill.id);

  return (
    <div className="navigation-panel">
      {/* <Icon type={IconTypeEnum.Share} size={IconSizeEnum.Size28} /> */}
      <div className="navigation-panel__links">
        <ul>
          <li>
            <Link to="/">{process.env.GATSBY_WEBSITE_NAME}</Link>
          </li>
          <li>
            <Link to={`/${CATALOG_PAGE_NAME}`}>Эксперты</Link>
          </li>
          {!!skill && !!lastBreadcrumb && (
            <li>
              <Link to={lastBreadcrumb.url}>{skill.name}</Link>
            </li>
          )}
        </ul>
      </div>
      {/* <Icon type={IconTypeEnum.Fav2} size={IconSizeEnum.Size28} /> */}
    </div>
  );
};

export default NavigationPanel;
