import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { roundToDecimal } from "@/utils/numberUtils";
import { declenateWord } from "@/utils/stringUtils";

import { StatisticsInfoProps } from "./types";
import "./styles.scss";

const StatisticsInfo = ({
  rating,
  reviewsCount,
  experienceInYears,
  totalSessionsCount,
}: StatisticsInfoProps) =>
  (
    <div className="statistics-info">
      <div className="statistics-info__number-container">
        <div className="statistics-info__number">
          <label>
            <span>{roundToDecimal(rating, 1)}</span>
            <Icon type={IconTypeEnum.Fav} size={IconSizeEnum.Size20} />
          </label>
        </div>
        <div className="statistics-info__text">
          {reviewsCount}
          {" "}
          {declenateWord(reviewsCount, ["оценка", "оценки", "оценок"])}
        </div>
      </div>
      <div className="statistics-info__number-container">
        <div className="statistics-info__number">
          {experienceInYears}
          {" "}
          {declenateWord(experienceInYears, ["год", "года", "лет"])}
        </div>
        <div className="statistics-info__text">опыта</div>
      </div>
      <div className="statistics-info__number-container">
        <div className="statistics-info__number">{totalSessionsCount}</div>
        <div className="statistics-info__text">
          {declenateWord(totalSessionsCount, ["консультация", "консультации", "консультаций"])}
        </div>
      </div>
    </div>
  );

export default StatisticsInfo;
