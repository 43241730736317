import React from "react";

import "./styles.scss";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import { scrollToScheduleProps } from "./types";

const ScrollToSchedule = ({ scrollPosition }: scrollToScheduleProps) => {
  const toScheduleScroll = () => {
    const positionScrollElement = scrollPosition?.current?.offsetTop;
    window.scroll({
      top: positionScrollElement,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="scroll-schedule"
      onClick={() =>
        toScheduleScroll()}
    >
      <div className="scroll-schedule__icon">
        <Icon type={IconTypeEnum.Calendar} size={IconSizeEnum.Size24} />
      </div>
      <p className="scroll-schedule__text">Расписание эксперта</p>
    </div>
  );
};

export default ScrollToSchedule;
