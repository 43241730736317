export const swiperBreakpoints = {
  319: {
    slidesPerView: 2,
    spaceBetween: 4,
  },
  375: {
    spaceBetween: 8,
  },
  480: {
    spaceBetween: 12,
  },
  750: {
    slidesPerView: 5,
    spaceBetween: 16,
  },
  1024: {
    spaceBetween: 16,
  },
};
