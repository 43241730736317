import { gql } from "@apollo/client";

export const GET_SCHEDULE = gql`
  query getSchedule($filter: GetScheduleFilter!) {
    getSchedule(filter: $filter) {
      start
      end
      type
      status
      id
      expertID
    }
  }
`;
