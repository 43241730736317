import { capitalize } from "lodash";

import { getExpertPageData_getExpert_skills } from "./graphql/__generated__/getExpertPageData";

export const getExpertDescription = (
  expertDescription: string,
  skills: getExpertPageData_getExpert_skills[] | null,
): string => {
  const skillsString = (skills ?? [])
    .map((skill) =>
      skill.badge)
    .join(", ") || "";

  const descriptionString = expertDescription
    .match(/<p>(.*?)<\/p>/g)?.[0]
    ?.replaceAll(/<\/?p>/g, "")
    .replaceAll("&nbsp;", " ")
    .replaceAll("\n", " ") || "";

  return `${skillsString ? `${capitalize(skillsString)}. ` : ``}${descriptionString}`;
};

export const getExpertTitle = (expertName: string): string =>
  (`${expertName} — lunaro.ru`);
