import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import { mockDataConsultation } from "./constants";

import "./styles.scss";

const DurationInfo = () =>
  (
    <div className="duration-info">
      <h2 className="duration-info__title">Сколько времени нужно на консультацию?</h2>
      <p className="duration-info__description">
        Вы сами решаете, сколько поговорить с экспертом. В среднем на разбор самых популярных
        вопросов уходит:
      </p>
      {mockDataConsultation.map((consultation) =>
        (
          <div className="duration-info__item" key={consultation.title}>
            <div className="duration-info__block-time">
              <p>{consultation.timeRange}</p>
              <p>{consultation.time}</p>
            </div>
            <div>
              <h4 className="duration-info__block-title">
                {consultation.title}
                {" "}
                {consultation.isFire && (
                <span className="duration-info__hit-fire">
                  Хит
                  <Icon size={IconSizeEnum.Size64} type={IconTypeEnum.Fire} />
                </span>
                )}
              </h4>
              <p className="duration-info__description">{consultation.description}</p>
            </div>
          </div>
        ))}
    </div>
  );

export default DurationInfo;
