import React, { useEffect, useRef, useState } from "react";

import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@/components/common/button";
import { IconTypeEnum } from "@/components/common/icon";
import { useMarketingHook } from "@/marketing/marketingHook";

import MobileAudioGreeting from "./components/mobileAudioGreeting";
import { AudioGreetingProps } from "./types";

import "./styles.scss";

const AudioGreeting = ({ audioGreetingUrl }: AudioGreetingProps) => {
  const { marketingAudioGreetingPlayHandler } = useMarketingHook();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [progresWidth, setProgresWidth] = useState(0);
  const [wasAudioPlayed, setWasAudioPlayed] = useState<boolean>(false);

  const togglePlayHandler = () => {
    if (audioRef.current?.paused) {
      audioRef.current?.play();
      if (!wasAudioPlayed) {
        setWasAudioPlayed(true);
        marketingAudioGreetingPlayHandler();
      }
    } else {
      audioRef.current?.pause();
    }
  };

  const getWitdhProgresBar = () => {
    const currentTime = audioRef.current?.currentTime || 0;
    const duration = audioRef.current?.duration || 0;
    const width = (currentTime / duration) * 100;
    setProgresWidth(Number(width));
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener("timeupdate", getWitdhProgresBar);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("timeupdate", getWitdhProgresBar);
      }
    };
  }, [audioGreetingUrl]);

  return (
    <>
      {audioGreetingUrl && (
      <div className="audio-greeting">
        <Button
          text="Слушать аудиоприветствие"
          size={ButtonSizeEnum.Middle}
          iconPosition={ButtonIconPositionEnum.Left}
          icon={audioRef.current?.paused ? IconTypeEnum.Play : IconTypeEnum.Pause}
          color={ButtonColorEnum.Light}
          className="audio-greeting__icon-play"
          onClick={togglePlayHandler}
        >
          <div
            style={{
              width: `${audioRef.current?.ended ? 0 : progresWidth}%`,
            }}
            className="audio-greeting__progres-audio"
          />
        </Button>

        <MobileAudioGreeting
          progresAudioWidth={progresWidth}
          togglePlayHandler={togglePlayHandler}
          iconAudio={audioRef.current?.paused ? IconTypeEnum.Play : IconTypeEnum.Pause}
          isEnded={audioRef.current?.ended}
        />
      </div>
      )}
      <audio src={audioGreetingUrl || ""} ref={audioRef} />
    </>
  );
};

export default AudioGreeting;
