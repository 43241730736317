import { gql } from "@apollo/client";

export const GET_MORE_EXPERT_REVIEWS = gql`
  query getMoreExpertReviewsNew($id: Int!, $after: String, $first: Int) {
    getReviews(expertID: $id, after: $after, first: $first) {
      edges {
        node {
          id
          text
          rating
          authorUser {
            image(size: 48) {
              onex
              twox
              threex
            }
            name
          }
          createdAtTimestamp
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
